export const FOOTER_PHRASES = {
  COPYRIGHT: "© Copyright 2021 by enkorwithus. All rights reserved.",
};

export const COMPANY_CONTACTS = {
  BUSINESS_REGISTRATION_NUMBER: "562 - 86 - 01724",
  CEO: "Oh Jung Hoon",
  TEL_NUMBER: "070 - 8803 - 1160",
  ADDRESS:
    "Room 1003 on the 10th floor of the samil building, 85, Cheonggyecheon-ro, Jongno-gu, Seoul",
  // 정보 관리 책임자
};

export const SOCIAL_LINKS = {
  ENKOR_STAY: "https://stay.enkor.kr/",
  ENKOR_BLOG: "https://stay.enkor.kr/blog",
  INSTAGRAM: "https://www.instagram.com/letsfip/",
  TIKTOK: "https://www.tiktok.com/@letsfip_",
};
