import { useEffect } from "react";
import Image from "next/image";

import {
  useAnimationControls,
  useScroll,
  useTransform,
  useMotionTemplate,
} from "@enkor/framer-motion";
import Lottie from "@enkor/react-lottie";

import * as eventCardsWebAnimationData from "@public/assets/landing/event-cards-lottie-web.json";
import * as eventCardsMobileAnimationData from "@public/assets/landing/event-cards-lottie-mobile.json";
import * as profileImagesWebAnimationData from "@public/assets/landing/profile-images-lottie-web.json";
import * as chatBubbleWebAnimationData from "@public/assets/landing/chat-bubble-lottie-web.json";
import { APP_STORE_LINK } from "@domains/common/constants/deepLinks";
import {
  FooterBlogIcon,
  FooterInstagramIcon,
  FooterStaySymbolIcon,
  FooterTiktokIcon,
  GooglePlayIcon,
  LoginAppleIcon,
} from "@enkor/design/icons";
import useWindowSize from "@logics/hooks/useWindowSize";
import { BREAKPOINT } from "@enkor/design/variables";
import {
  COMPANY_CONTACTS,
  FOOTER_PHRASES,
  SOCIAL_LINKS,
} from "@domains/common/constants/footer";
import { grayScale } from "@enkor/design/colors";
import { EXTERNAL_LINK } from "@domains/common/constants/externalLinks";
import { dataTrackEvent } from "@logics/utils/dataTrackEvent";
import { DATA_TRACKING_EVENT_NAME } from "@domains/common/constants/dataTrackEventName";

import OngoingSection from "../components/OngoingSection/OngoingSection";

import * as S from "./Landing.style";

const lottieOption = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Landing = () => {
  const { width } = useWindowSize();

  const introContainerControls = useAnimationControls();
  const mobileBackgroundControls = useAnimationControls();

  useEffect(() => {
    introContainerControls.start({ x: [-80, 0], opacity: [0, 1] });
    mobileBackgroundControls.start({ y: [40, 0], opacity: [0, 1] });
  }, []);

  const { scrollYProgress } = useScroll();

  const card1Scale = useTransform(scrollYProgress, [0.65, 0.75], [1, 0.8]);
  const card2Scale = useTransform(scrollYProgress, [0.7, 0.75], [1, 0.9]);
  const card2TransformY = useTransform(scrollYProgress, [0.55, 0.65], [0, 32]);
  const card2Style = useMotionTemplate`scale(${card2Scale}) translateY(${card2TransformY}px)`;
  const card3TransformY = useTransform(scrollYProgress, [0.55, 0.75], [0, 64]);

  const openGooglePlay = () => window.open(APP_STORE_LINK.ANDROID, "_blank");
  const openAppStore = () => window.open(APP_STORE_LINK.IOS, "_blank");

  return (
    <S.Container>
      <S.Section1>
        <Image
          src="/assets/landing/pink-cloud-object.webp"
          alt="pink-cloud-object"
          width={1087}
          height={1087}
          style={{
            position: "absolute",
            left: "-623px",
            bottom: "-443px",
            objectFit: "cover",
          }}
        />
        <Image
          src="/assets/landing/purple-cloud-object.webp"
          alt="purple-cloud-object"
          width={904}
          height={904}
          style={{
            position: "absolute",
            top: "-134px",
            right: "-469px",
            objectFit: "cover",
          }}
        />
        <S.SectionContentWrapper
          animate={introContainerControls}
          transition={{ delay: 0.2, duration: 0.7, ease: "easeInOut" }}
        >
          <S.LogoImage
            src="/logo/fip-logo-pink.png"
            alt="main-logo"
            width={92}
            height={53}
          />
          <S.MainTitleWrapper>
            <S.MainTitle>
              {"Different Languages,\nBut Same Tastes!"}
            </S.MainTitle>
            <S.ShiningStars
              src="/assets/landing/shining-stars.png"
              alt="shining-stars-emoji"
              width={80}
              height={80}
            />
          </S.MainTitleWrapper>
          <S.AppDownloadButtonContainer>
            <S.AppDownloadButton
              onClick={() => {
                dataTrackEvent({
                  eventName:
                    DATA_TRACKING_EVENT_NAME.LANDING.HERO_CTA_GOOGLE_PLAY,
                  properties: { method: "googleplay" },
                });

                openGooglePlay();
              }}
            >
              <GooglePlayIcon size={28} strokeColor="transparent" />
              <span>GooglePlay</span>
            </S.AppDownloadButton>
            <S.AppDownloadButton
              onClick={() => {
                dataTrackEvent({
                  eventName:
                    DATA_TRACKING_EVENT_NAME.LANDING.HERO_CTA_APP_STORE,
                  properties: { method: "appstore" },
                });

                openAppStore();
              }}
            >
              <LoginAppleIcon size={28} strokeColor="transparent" />
              <span>App Store</span>
            </S.AppDownloadButton>
          </S.AppDownloadButtonContainer>
        </S.SectionContentWrapper>

        <S.MobilePhoneBackgroundImage
          animate={mobileBackgroundControls}
          transition={{ delay: 0.8, duration: 0.7, ease: "easeInOut" }}
        />
      </S.Section1>

      <S.Section2>
        <S.Section2ContentWrapper>
          <S.Section2TitleDescriptionWrapper>
            <S.Section2Title>
              {"Not sure what to do\nin Seoul?"}
            </S.Section2Title>
            <S.Section2Description>
              Here’s the best way to have fun and make friends
            </S.Section2Description>
          </S.Section2TitleDescriptionWrapper>

          <S.OngoingSectionWrapper>
            <OngoingSection />
          </S.OngoingSectionWrapper>
        </S.Section2ContentWrapper>
      </S.Section2>

      <S.Section3>
        <S.Section3ContentWrapper>
          <S.Section3Title>See what others are doing</S.Section3Title>
          <S.ReviewCardImage />
        </S.Section3ContentWrapper>

        <S.EventBackgroundImage />
      </S.Section3>

      <S.Section4>
        <S.Section4BackgroundImage
          src="/assets/landing/pink-cloud-object-2.webp"
          alt="pink-cloud-object-2"
          width={1157}
          height={1157}
        />

        <S.Section4Title>Ready to try FIP?</S.Section4Title>
        <S.StackedCard
          style={{
            scale: card1Scale,
            willChange: "transform",
          }}
        >
          <S.CardTitleDescriptionWrapper>
            <S.CardTitle>{"Join and make global\nfriends!"}</S.CardTitle>
            <S.CardDescription>
              {"Experience the real local Seoul with\nfrends who get you."}
            </S.CardDescription>
          </S.CardTitleDescriptionWrapper>

          <S.LottieWrapper>
            <Lottie
              options={{
                ...lottieOption,
                animationData:
                  (width || 0) > parseInt(BREAKPOINT.PAD)
                    ? eventCardsWebAnimationData
                    : eventCardsMobileAnimationData,
              }}
              speed={0.8}
            />
          </S.LottieWrapper>
        </S.StackedCard>

        <S.StackedCard
          style={{
            transform: card2Style,
            willChange: "transform",
          }}
        >
          <S.CardTitleDescriptionWrapper>
            <S.CardTitle>{"Host & Explore\ntogether!"}</S.CardTitle>
            <S.CardDescription>
              {
                "Create and share events with Hosting,\nand connect through new experiences."
              }
            </S.CardDescription>
          </S.CardTitleDescriptionWrapper>

          <S.ScaleDownLottieWrapper>
            <Lottie
              options={{
                ...lottieOption,
                animationData: profileImagesWebAnimationData,
              }}
              speed={0.8}
            />
          </S.ScaleDownLottieWrapper>
        </S.StackedCard>

        <S.StackedCard
          style={{
            translateY: card3TransformY,
            willChange: "transform",
          }}
        >
          <S.CardTitleDescriptionWrapper>
            <S.CardTitle>{"Get closer\nthrough chat."}</S.CardTitle>
            <S.CardDescription>
              {"Chat, share, and plan your next meetup\nwith ease."}
            </S.CardDescription>
          </S.CardTitleDescriptionWrapper>
          <S.ScaleDownLottieWrapper>
            <Lottie
              options={{
                ...lottieOption,
                animationData: chatBubbleWebAnimationData,
              }}
              speed={0.8}
            />
          </S.ScaleDownLottieWrapper>
        </S.StackedCard>
      </S.Section4>

      <S.Section5>
        <S.Section5ContentWrapper>
          <S.MockupImageContainer>
            <S.MobileMockupImage
              src="/assets/landing/mobile-mockup.webp"
              alt="fip-mobile-mockup"
              width={598}
              height={548}
              style={{
                opacity: 0,
                zIndex: 2,
              }}
              whileInView={{ y: [20, 0], opacity: [0, 1] }}
              transition={{ delay: 0.3, duration: 0.8, ease: "easeInOut" }}
            />

            <S.MobileMockupImage
              src="/assets/landing/mobile-mockup-shadow.webp"
              alt="fip-mobile-mockup-shadow"
              width={598}
              height={548}
              style={{
                opacity: 0,
                zIndex: 1,
              }}
              whileInView={{ opacity: [0, 1], scale: [1, 1.1] }}
              transition={{ delay: 0.5, duration: 0.8, ease: "easeInOut" }}
            />
          </S.MockupImageContainer>

          <S.QRContainer>
            <S.QRWrapper>
              <S.QRPhrase>
                {"Stop thinking, start enjoying!\nletsfip"}
              </S.QRPhrase>
              <S.QRImage
                src="/assets/landing/qr-link.png"
                alt="qr-link"
                width={208}
                height={208}
              />
              <S.DownloadPhrase>Download Now</S.DownloadPhrase>
            </S.QRWrapper>
            <S.AppDownloadButtonContainer>
              <S.AppDownloadButton
                onClick={() => {
                  dataTrackEvent({
                    eventName:
                      DATA_TRACKING_EVENT_NAME.LANDING.END_CTA_GOOGLE_PLAY,
                    properties: { method: "googleplay" },
                  });

                  openGooglePlay();
                }}
              >
                <GooglePlayIcon size={28} strokeColor="transparent" />
                <span>GooglePlay</span>
              </S.AppDownloadButton>
              <S.AppDownloadButton
                onClick={() => {
                  dataTrackEvent({
                    eventName:
                      DATA_TRACKING_EVENT_NAME.LANDING.END_CTA_APP_STORE,
                    properties: { method: "appstore" },
                  });

                  openAppStore();
                }}
              >
                <LoginAppleIcon size={28} strokeColor="transparent" />
                <span>App Store</span>
              </S.AppDownloadButton>
            </S.AppDownloadButtonContainer>
          </S.QRContainer>
        </S.Section5ContentWrapper>

        <S.FooterWrapper>
          <S.CopyrightWrapper>
            <S.LogoImage
              src="/logo/fip-logo-white.png"
              alt="footer-logo"
              width={47}
              height={27}
              style={{
                width: "47px",
                height: "27px",
              }}
            />
            <S.CopyrightPhrase>{FOOTER_PHRASES.COPYRIGHT}</S.CopyrightPhrase>
            <S.CompanyInfo>
              {`Business registration number : ${COMPANY_CONTACTS.BUSINESS_REGISTRATION_NUMBER}\n`}
              {`CEO : ${COMPANY_CONTACTS.CEO} | TEL : ${COMPANY_CONTACTS.TEL_NUMBER}\n`}
              {`Mail order business report number: ${COMPANY_CONTACTS.ADDRESS}`}
            </S.CompanyInfo>
          </S.CopyrightWrapper>
          <S.FooterMenuWrapper>
            <S.MenuList>
              {/* MEMO: 임시 제거 */}
              {/* <S.MenuItem href="/" target="_blank">
                Announcements
              </S.MenuItem> */}
              <S.MenuItem href={EXTERNAL_LINK.TERMS_OF_SERVICE} target="_blank">
                Terms of Service
              </S.MenuItem>
              <S.MenuItem href={EXTERNAL_LINK.PRIVACY_POLICY} target="_blank">
                Privacy Policy
              </S.MenuItem>
            </S.MenuList>

            <S.SocialLinkWrapper>
              <S.SocialLink href={SOCIAL_LINKS.ENKOR_STAY} target="_blank">
                <FooterStaySymbolIcon size={18} strokeColor={grayScale[700]} />
              </S.SocialLink>
              <S.SocialLink href={SOCIAL_LINKS.ENKOR_BLOG} target="_blank">
                <S.ColoredIcon>
                  <FooterBlogIcon size={18} />
                </S.ColoredIcon>
              </S.SocialLink>
              <S.SocialLink href={SOCIAL_LINKS.INSTAGRAM} target="_blank">
                <S.ColoredIcon>
                  <FooterInstagramIcon size={18} strokeColor={grayScale[700]} />
                </S.ColoredIcon>
              </S.SocialLink>
              <S.SocialLink href={SOCIAL_LINKS.TIKTOK} target="_blank">
                <S.ColoredIcon>
                  <FooterTiktokIcon size={18} />
                </S.ColoredIcon>
              </S.SocialLink>
            </S.SocialLinkWrapper>
          </S.FooterMenuWrapper>
        </S.FooterWrapper>
      </S.Section5>
    </S.Container>
  );
};

export default Landing;
