export const EXTERNAL_LINK = {
  PRIVACY_POLICY:
    "https://plexgathering.notion.site/FIP-08522bc21d7141759ebbcac03f98da86",
  HOST_GUEST_POLICY:
    "https://plexgathering.notion.site/FIP-08522bc21d7141759ebbcac03f98da86",
  CANCELLATION_AND_REFUND_POLICY:
    "https://plexgathering.notion.site/Guest-Policy-b3e5ce42287747a8859ee038d5cb255f?pvs=4",
  TERMS_OF_CONDITIONS:
    "https://plexgathering.notion.site/Terms-of-Service-8dfb6702f25a45668826841cebba4bbb?pvs=4",
  TERMS_OF_SERVICE:
    "https://letsfip.notion.site/FIP-c8cf92e54927426c9085b108139a4d92?pvs=4",
};
